import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

function Section(props) {
  const { className = '', children, hasGrid = false, tag = false, fullScreen = false, ...other } = props;

  const Tag = tag ? tag : 'section';

  return (
    <>
      <Tag
        className={`${css['atom__section-container']} ${className}`}
        {...other}
        data-grid={hasGrid ? true : undefined}
        data-full-screen={fullScreen}
      >
        {children}
      </Tag>
    </>
  );
}

export default Section;
