import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Transition from 'utils/hocs/transition';
import { enableDarkThemeFromOS } from '__config/styleguide/colors';
import css from './styles.module.scss';

// import Header from 'components/organisms/header';
import Footer from 'components/organisms/footer';
import SkipLinks from 'components/molecules/skip-links';

const Layout = ({ children }) => {
  const router = useRouter();

  const [timestamp, setTimestamp] = useState(null);

  useEffect(() => {
    setTimestamp(new Date().toUTCString());
  }, []);

  return (
    <div className={css['layout']} data-generated-at={timestamp} data-theme={enableDarkThemeFromOS ? 'os' : 'light'}>
      <SkipLinks />
      {/* <Header isRendering={router.isFallback} /> */}
      <Transition location={router.pathname}>
        {/* <main id="main"> */}
        {!router.isFallback && children}
        {/* </main> */}
      </Transition>

      {!router.isFallback && <Footer locale={router?.locale?.toLowerCase()} />}
    </div>
  );
};

export default Layout;
