import React, { createContext, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const UserCustomerContext = createContext();

function UserCustomerProvider({ children }) {
  const [userDataCustomer, setUserDataCustomer] = useState(null);
  const router = useRouter();
  const localeAr = router?.locale.toLowerCase() === 'es-ar';

  async function fetchData() {
    try {
      const response = await fetch('/api/userCustomer');
      if (!response.ok) {
        const errorMessage = `Unable to obtain user data: ${response.status} - ${response.statusText}`;
        throw new Error(errorMessage);
      }
      const data = await response.json();
      setUserDataCustomer(data?.status);

      const pageUrl = '/mi-toyota/login';

      if (location.href.includes(pageUrl)) {
        const urlParams = new URLSearchParams(location?.search || '');

        if (!urlParams.get('section')) {
          urlParams.set('section', 'vehicles');
        }

        if (data?.status && data?.status?.customer && data?.status?.customer?.sfAccountId) {
          router.push(`${pageUrl}?${urlParams.toString()}`);
        } else {
          router.push(`${pageUrl}?section=account`);
        }
      }
    } catch (error) {
      console.error('Error getting user data:', error);
    }
  }

  async function updateUserData() {
    try {
      await fetchData();
      return true;
    } catch (error) {
      console.error('Error updating user data:', error);
      return false;
    }
  }

  useEffect(() => {
    if (!userDataCustomer && localeAr) {
      fetchData();
    }
  }, []);

  const value = {
    userDataCustomer,
    setUserDataCustomer,
    updateUserData
  };

  return <UserCustomerContext.Provider value={value}>{children}</UserCustomerContext.Provider>;
}

function useUserCustomer() {
  const context = useContext(UserCustomerContext);
  if (context === undefined) {
    throw new Error('useUserCustomer must be used within a UserCustomerProvider');
  }
  return context;
}

export { UserCustomerProvider, useUserCustomer, UserCustomerContext };
