import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Wrapper
 *
 * A wrapper for components
 */
function Wrapper(props) {
  const {
    className = '',
    children,
    spacingTop = null,
    spacingBottom = null,
    spacing = null,
    bgColor = 'default',
    marginTop = null,
    marginBottom = null,
    centerContent = false,
    ...other
  } = props;

  return (
    <div
      className={`${css['section-wrapper']} ${className}`}
      data-spacing={spacing}
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
      data-margin-top={marginTop}
      data-margin-bottom={marginBottom}
      data-bg-color={bgColor}
      data-center-content={centerContent}
      {...other}
    >
      {children}
    </div>
  );
}

export default Wrapper;
