export const primaryLinksByCountry = {
  BR: [
    {
      linkLabel: 'Trabalhe conosco',
      link: 'https://toyotabrasil.gupy.io/'
    },
    {
      linkLabel: 'Imprensa',
      link: 'https://www.toyotacomunica.com.br/'
    }
  ],
  AR: [
    {
      linkLabel: 'Concesionarios',
      link: 'https://www.toyota.com.ar/encontra-tu-concesionario-toyota'
    },
    {
      linkLabel: 'Lexus',
      link: 'https://www.lexus.com.ar/'
    }
  ],
  VE: [
    {
      linkLabel: 'Acerca de Toyota',
      link: '/acerca-de-toyota'
    },
    {
      linkLabel: 'Contáctanos',
      link: '/atencion-al-cliente'
    }
  ]
};

export const secondaryLinksByCountry = {
  BR: [
    {
      linkLabel: 'Termos de uso',
      link: '/termos-de-uso'
    },
    {
      linkLabel: 'Alerta de golpe',
      link: '/alerta-aos-consumidores'
    },
    {
      linkLabel: 'Compliance',
      link: '/mundo-toyota/posicionamento-esg/compliance-toyota'
    },
    {
      linkLabel: 'Politica de privacidade',
      link: '/politica-de-privacidade'
    },
    {
      linkLabel: 'Formulário de solicitação de privacidade (LGPD)',
      link: '/portal-de-privacidade'
    }
  ],
  AR: [
    {
      linkLabel: 'Libro de quejas',
      link: '/libro-de-quejas'
    },
    {
      linkLabel: 'Política de privacidad',
      link: '/politica-de-privacidad'
    },
    {
      linkLabel: 'Botón de arrepentimiento',
      link: '/arrepentimiento'
    },
    {
      linkLabel: 'Defensa de las y los consumidores. Para reclamos ingrese aquí',
      link: 'https://autogestion.produccion.gob.ar/consumidores'
    },
    {
      linkLabel: 'Defensa de las y los consumidores. Alerta de Productos. Ingrese aquí',
      link: 'https://www.argentina.gob.ar/produccion/defensadelconsumidor/alertas-de-productos'
    },
    {
      linkLabel: 'Aprobaciones de Equipos de Radiofrecuencia y Mapas',
      link: '/docs/es-ar/aprobaciones-de-equipos-de-radiofrecuencia-y-mapa.pdf'
    },
    {
      linkLabel: 'Alerta y retiro de productos',
      link: 'https://media.toyota.com.ar/f79b7a6f-2b47-46ee-90ca-a3d07724413b.pdf'
    }
  ],
  VE: [
    {
      linkLabel: 'Términos legales',
      link: '/terminos-legales'
    },
    {
      linkLabel: 'Políticas de Privacidad',
      link: '/politica-de-privacidad'
    },
    {
      linkLabel: 'Compliance',
      link: '/compliance'
    }
  ]
};

export const extraLinkTasa = {
  linkLabel: 'Términos y condiciones',
  link: '/terminos-y-condiciones'
};

export const socialLinks = {
  BR: [
    { type: 'instagram', url: 'https://instagram.com/toyotadobrasil/' },
    { type: 'facebook', url: 'https://www.facebook.com/ToyotaDoBrasil' },
    { type: 'youtube', url: 'https://www.youtube.com/user/toyotabrasil' },
    { type: 'linkedin', url: 'https://www.linkedin.com/company/toyota-do-brasil' }
  ],
  AR: [
    { type: 'instagram', url: 'https://www.instagram.com/toyotaargentina/' },
    { type: 'facebook', url: 'https://www.facebook.com/ToyotaArgentina' },
    { type: 'youtube', url: 'https://www.youtube.com/user/ToyotaArg' },
    { type: 'linkedin', url: 'https://www.linkedin.com/company/toyota-argentina/' }
  ],
  VE: [
    { type: 'instagram', url: 'https://www.instagram.com/toyotavenezuelaoficial/' },
    { type: 'facebook', url: 'https://www.facebook.com/ToyotaVenezuelaOficial/' },
    { type: 'linkedin', url: 'https://ve.linkedin.com/company/toyota-de-venezuela' },
    { type: 'x', url: 'https://x.com/ToyotaOficialVe' }
  ]
};
