import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';

const CarBuilderContext = createContext();

function CarBuilderProvider ({ children }) {
  // whatever you set here as default, will be used in both server side and client as well
  const [providerData, setProviderData] = useState({
    ready: false,
    data: {
      // add default data to your provider here
    }
  });

  // you can get extra data asynchronously if needed
  // this will only affect the client side
  function fetchProviderData () {
    //! TODO: Add your code here to fetch or mount data to fill the provider with. This will be executed only in serveer side
    setProviderData({
      ...providerData,
      ready: true,
    });
  }

  useEffect(() => { fetchProviderData(); }, []);

  const value = {
    ...providerData,
    setData (key, value) {
      setProviderData({
        ...providerData,
        data: {
          ...providerData.data,
          [key]: value
        }
      });
    }
    // TODO: add more methods/actions here
  };

  return (
    <CarBuilderContext.Provider value={value}>

      {children}

      {/*
        //! you can add extra components here
       */}
    </CarBuilderContext.Provider>
  );
}

// this allows you to use it as a simpler hook
function useCarBuilder () {
  const context = useContext(CarBuilderContext);
  if (context === undefined) {
    throw new Error('useCarBuilder must be used within a CarBuilderProvider');
  }

  return context;
}

export {
  CarBuilderProvider,
  CarBuilderContext,
  useCarBuilder
};
