//? read more at https://nextjs.org/docs/advanced-features/i18n-routing

/* eslint-disable no-unused-vars */
const x = undefined;
const n = 'numeric';
const l = 'long';
const s = 'short';
/* eslint-enable no-unused-vars */

/* eslint-disable max-len */
module.exports = {
  locales: [process.env.DEFAULT_LOCALE],
  defaultLocale: process.env.DEFAULT_LOCALE,
  localeDetection: false,
  timeZone: process.env.DEFAULT_TZ,

  formats: {
    dateTime: {
      shortTime: {
        weekday: x,
        day: x,
        month: x,
        year: x,
        hour: n,
        minute: n,
        second: x,
        hour12: false
      },
      longTime: {
        weekday: x,
        day: x,
        month: x,
        year: x,
        hour: n,
        minute: n,
        second: n,
        hour12: false
      },
      shortDate: {
        weekday: x,
        day: n,
        month: n,
        year: n,
        hour: x,
        minute: x,
        second: x,
        hour12: false
      },
      longDate: {
        weekday: x,
        day: n,
        month: l,
        year: n,
        hour: x,
        minute: x,
        second: x,
        hour12: false
      },
      fullTime: {
        weekday: l,
        day: n,
        month: l,
        year: n,
        hour: n,
        minute: n,
        second: n,
        hour12: false
      }
    },
    number: {
      full: {
        maximumFractionDigits: 9
      },
      precise: {
        maximumFractionDigits: 4
      },
      currency: {
        maximumFractionDigits: 2
      },
      USD: { maximumFractionDigits: 2, style: 'currency', currency: 'USD' },
      BRL: { maximumFractionDigits: 2, style: 'currency', currency: 'BRL' },
      ARS: { maximumFractionDigits: 2, style: 'currency', currency: 'USD' },
      EUR: { maximumFractionDigits: 2, style: 'currency', currency: 'EUR' },
      JPY: { maximumFractionDigits: 2, style: 'currency', currency: 'JPY' }
    }
  },
  defaultFallback: `[not-translated]` // use '*' to show the key itself, instead of a default message
};
/* eslint-enable max-len */
