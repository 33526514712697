import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Layout from 'utils/hocs/layout';
import { applyContexts } from 'utils/providers';

import 'utils/styles/_reset.scss';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  if (router.route === '/sitemap.txt') {
    return <Component {...pageProps} />;
  }

  return applyContexts(
    <Layout>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="sitemap" type="application/xml" title="Sitemap" href="/api/sitemap.xml" />
      </Head>
      <Component {...pageProps} />
    </Layout>,
    { pageProps }
  );
}

export default MyApp;
