import React, { createContext, useContext } from 'react';
import Script from 'next/script';

const BotMakerContext = createContext();

function BotMakerProvider({ children, locale }) {
  return (
    <BotMakerContext.Provider value={{ locale }}>
      {children}

      {locale === 'es-ar' && (
        <Script
          id="botmaker-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function () { let js = document.createElement('script');
              js.type = 'text/javascript';
              js.async = 1;
              js.src = 'https://go.botmaker.com/rest/webchat/p/0BOZKVI13N/init.js';
              document.body.appendChild(js);
            })();
              `
          }}
        />
      )}
    </BotMakerContext.Provider>
  );
}

function useBotMaker() {
  const context = useContext(BotMakerContext);
  if (context === undefined) {
    throw new Error('useBotMaker must be used within a BotMakerProvider');
  }

  return context;
}

export { BotMakerProvider, BotMakerContext, useBotMaker };
