/* eslint-disable max-len */
import React from 'react';
import i18n from 'utils/i18n';

import AnchorLink from 'components/atoms/anchor-link';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';

import { primaryLinksByCountry, secondaryLinksByCountry, extraLinkTasa, socialLinks } from './links';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism Footer
 *
 * The portal footer
 */

function Footer(props) {
  const t = i18n.useTranslations('components.footer');

  const { locale = 'pt-br' } = props;

  const isTDB = locale === 'pt-br';
  const isTDV = locale === 'es-ve';
  const isTASA = locale === 'es-ar';

  const country = `${locale}`.slice(-2).toUpperCase();

  const logoSrc = `/images/logos/logo-toyota--horizontal-${locale}.svg`;

  const primaryLinks = primaryLinksByCountry[country] || [];
  const secondaryLinks = secondaryLinksByCountry[country] || [];
  const socialLinksForCountry = socialLinks[country] || [];

  const renderEverythingThatMoves = () => {
    const everythingThatMovesSrc = `/images/logos/everything-that-moves--${locale}.svg`;
    if (isTDV || isTDB) {
      return (
        <div className={css['everything-that-moves-container']}>
          <img
            src={everythingThatMovesSrc}
            alt={`Logo Everything That Moves (${locale})`}
            className={css['everything-that-moves-container__logo']}
          />
        </div>
      );
    }
  };

  const renderIbamaContainer = () => {
    if (isTDB) {
      return (
        <div className={css['ibama-container']}>
          <img src="/images/ibama-logo.png" alt="" className={css['ibama-container__logo']} />
          <span className={css['ibama-container__lede']}>{t('content_ibama')}</span>
        </div>
      );
    }
  };

  const renderTASAExtraLink = () => {
    if (isTASA) {
      return (
        <div className={css['footer-links-tasa']}>
          <p className={css['footer-disclaimer']}>{t('text_copyright')}</p>
          {extraLinkTasa && (
            <AnchorLink
              color="white"
              linkLabel={extraLinkTasa?.linkLabel}
              size="small"
              hasArrow={false}
              link={extraLinkTasa?.link}
            />
          )}
        </div>
      );
    }
  };

  const getSocialIcon = (type) => {
    const iconSrc = `/images/social-media-icons/${type}.svg`;
    const altText = `${type.charAt(0).toUpperCase() + type.slice(1)} Icon`;
    return <img src={iconSrc} alt={altText} />;
  };

  return (
    <>
      <Wrapper className={css['footer']} spacingTop="5" spacingBottom="5" bgColor="black" data-locale={country}>
        <Section>
          <div className={css['footer-header']}>
            <img src={logoSrc} alt="" className={css['footer-header__logo']} />
            {renderEverythingThatMoves()}
          </div>
          <div className={css['footer-content']}>
            <div className={css['footer-content__content']}>
              {renderIbamaContainer()}
              {primaryLinks?.length > 0 && (
                <ul className={css['footer-links']}>
                  {primaryLinks?.map((link, index) => (
                    <li key={index}>
                      <AnchorLink
                        color="white"
                        linkLabel={link?.linkLabel}
                        size="small"
                        link={link?.link}
                        hasArrow={false}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <ul className={css['social-links']}>
              {socialLinksForCountry?.map((link, index) => (
                <li key={index}>
                  <a href={link.url} className={css['social-links__link']} target="_blank" rel="noopener noreferrer">
                    {link.type && getSocialIcon(link.type)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {renderTASAExtraLink()}
        </Section>
      </Wrapper>

      {secondaryLinks?.length > 0 && (
        <Wrapper spacing="3" bgColor="gray-6" className={css['footer-links-list']}>
          <Section tag="div">
            <ul className={css['links-list']}>
              {secondaryLinks?.map((link, index) => (
                <li key={index}>
                  <AnchorLink
                    color="white"
                    linkLabel={link?.linkLabel}
                    size="small"
                    link={link?.link}
                    hasArrow={false}
                  />
                </li>
              ))}
            </ul>
          </Section>
        </Wrapper>
      )}
    </>
  );
}

export default Footer;
